<template>
  <div class="bottom-button-wrapper">
    <slot name="button" />
  </div>
</template>
<script>
export default {
  name: 'ListBottomButtonBox',
  props: [],
  data() {
    return {};
  },
  computed: {},
  created() {},
  mounted() {},
  watch: {},
  methods: {},
  components: {},
};
</script>
<style scoped lang="scss">
@import 'List';
</style>
